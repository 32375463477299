/*Fontface Styles*/
@font-face {
  font-family: "Poppins-reg";
  src: url("../fonts/Poppins-Regular.eot");
  src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype"),
    url("../fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-semibold";
  src: url("../fonts/Poppins-SemiBold.eot");
  src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype"),
    url("../fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-bold";
  src: url("../fonts/Poppins-Bold.eot");
  src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff"),
    url("../fonts/Poppins-Bold.ttf") format("truetype"),
    url("../fonts/Poppins-Bold.svg#Poppins-bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

figure,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-style: normal;
}
button,
input,
select,
textarea {
  outline: 0 !important;
}
a {
  text-decoration: none;
}
a:focus,
button:focus,
input:focus {
  outline: 0 none;
}
ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
article:before,
aside:before,
footer:before,
header:before,
nav:before,
section:before {
  content: " ";
}
article:after,
aside:after,
footer:after,
header:after,
nav:after,
section:after {
  content: " ";
  clear: both;
}
