@import "./assets/styles/styles.scss";

* {
    margin: 0;
    padding: 0;
}

/* hide scrollbar but allow scrolling */
body {
    -ms-overflow-style: none !important;
    /* for Internet Explorer, Edge */
    scrollbar-width: none !important;
    /* for Firefox */
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    background-color: $themeColor2;
}

* {
    font-family: "Poppins-Semibold" !important;
}

body::-webkit-scrollbar {
    display: none !important;
    /* for Chrome, Safari, and Opera */
}

@font-face {
    font-family: "Futura-Light";
    src: url("/assets/fonts/FuturaBT-Light.eot");
    src: url("/assets/fonts/FuturaBT-Light.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/FuturaBT-Light.woff2") format("woff2"),
        url("/assets/fonts/FuturaBT-Light.woff") format("woff"),
        url("/assets/fonts/FuturaBT-Light.ttf") format("truetype"),
        url("/assets/fonts/FuturaBT-Light.svg#FuturaBT-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Futura-Bold";
    src: url("/assets/fonts/Futura-Bold.eot");
    src: url("/assets/fonts/Futura-Bold.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Futura-Bold.woff2") format("woff2"),
        url("/assets/fonts/Futura-Bold.woff") format("woff"),
        url("/assets/fonts/Futura-Bold.ttf") format("truetype"),
        url("/assets/fonts/Futura-Bold.svg#Futura-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Futura-BlackBold";
    src: url("./assets/fonts/Futura-Black-Bold.eot");
    src: url("./assets/fonts/Futura-Black-Bold.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/Futura-Black-Bold.woff2") format("woff2"),
        url("./assets/fonts/Futura-Black-Bold.woff") format("woff"),
        url("./assets/fonts/Futura-Black-Bold.ttf") format("truetype"),
        url("./assets/fonts/Futura-Black-Bold.svg#Futura-Black-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Futura-Medium";
    src: url("./assets/fonts/FuturaBT-Medium.eot");
    src: url("./assets/fonts/FuturaBT-Medium.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/FuturaBT-Medium.woff2") format("woff2"),
        url("./assets/fonts/FuturaBT-Medium.woff") format("woff"),
        url("./assets/fonts/FuturaBT-Medium.ttf") format("truetype"),
        url("./assets/fonts/FuturaBT-Medium.svg#FuturaBT-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Fonts Generated using client shared fonr files*/
@font-face {
    font-family: "futura_stdbold_condensed";
    src: url("./assets/fonts/futurastd-condensedbold-webfont.eot");
    src: url("./assets/fonts/futurastd-condensedbold-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-condensedbold-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-condensedbold-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-condensedbold-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-condensedbold-webfont.svg#futura_stdbold_condensed") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdBdCnOb";
    src: url("./assets/fonts/futurastd-condensedboldobl-webfont.eot");
    src: url("./assets/fonts/futurastd-condensedboldobl-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-condensedboldobl-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-condensedboldobl-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-condensedboldobl-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-condensedboldobl-webfont.svg#futura_stdBdCnOb") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdXBdCn";
    src: url("./assets/fonts/futurastd-condensedextrabd-webfont.eot");
    src: url("./assets/fonts/futurastd-condensedextrabd-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-condensedextrabd-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-condensedextrabd-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-condensedextrabd-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-condensedextrabd-webfont.svg#futura_stdXBdCn") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdlight_condensed";
    src: url("./assets/fonts/futurastd-condensedlight-webfont.eot");
    src: url("./assets/fonts/futurastd-condensedlight-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-condensedlight-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-condensedlight-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-condensedlight-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-condensedlight-webfont.svg#futura_stdlight_condensed") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdLtCnOb";
    src: url("./assets/fonts/futurastd-condensedlightobl-webfont.eot");
    src: url("./assets/fonts/futurastd-condensedlightobl-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-condensedlightobl-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-condensedlightobl-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-condensedlightobl-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-condensedlightobl-webfont.svg#futura_stdLtCnOb") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdbold";
    src: url("./assets/fonts/futurastd-bold-webfont.eot");
    src: url("./assets/fonts/futurastd-bold-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-bold-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-bold-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-bold-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-bold-webfont.svg#futura_stdbold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdbold_oblique";
    src: url("./assets/fonts/futurastd-boldoblique-webfont.eot");
    src: url("./assets/fonts/futurastd-boldoblique-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-boldoblique-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-boldoblique-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-boldoblique-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-boldoblique-webfont.svg#futura_stdbold_oblique") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdbook";
    src: url("./assets/fonts/futurastd-book-webfont.eot");
    src: url("./assets/fonts/futurastd-book-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-book-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-book-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-book-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-book-webfont.svg#futura_stdbook") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdbook_oblique";
    src: url("./assets/fonts/futurastd-bookoblique-webfont.eot");
    src: url("./assets/fonts/futurastd-bookoblique-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-bookoblique-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-bookoblique-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-bookoblique-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-bookoblique-webfont.svg#futura_stdbook_oblique") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdmedium_condensed";
    src: url("./assets/fonts/futurastd-condensed-webfont.eot");
    src: url("./assets/fonts/futurastd-condensed-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-condensed-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-condensed-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-condensed-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-condensed-webfont.svg#futura_stdmedium_condensed") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdlight_oblique";
    src: url("./assets/fonts/futurastd-lightoblique-webfont.eot");
    src: url("./assets/fonts/futurastd-lightoblique-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-lightoblique-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-lightoblique-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-lightoblique-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-lightoblique-webfont.svg#futura_stdlight_oblique") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdmedium";
    src: url("./assets/fonts/futurastd-medium-webfont.eot");
    src: url("./assets/fonts/futurastd-medium-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-medium-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-medium-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-medium-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-medium-webfont.svg#futura_stdmedium") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdmedium_oblique";
    src: url("./assets/fonts/futurastd-mediumoblique-webfont.eot");
    src: url("./assets/fonts/futurastd-mediumoblique-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-mediumoblique-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-mediumoblique-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-mediumoblique-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-mediumoblique-webfont.svg#futura_stdmedium_oblique") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdMdCnOb";
    src: url("./assets/fonts/futurastd-condensedoblique-webfont.eot");
    src: url("./assets/fonts/futurastd-condensedoblique-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-condensedoblique-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-condensedoblique-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-condensedoblique-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-condensedoblique-webfont.svg#futura_stdMdCnOb") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdXBdCnOb";
    src: url("./assets/fonts/futurastd-condextraboldobl-webfont.eot");
    src: url("./assets/fonts/futurastd-condextraboldobl-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-condextraboldobl-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-condextraboldobl-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-condextraboldobl-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-condextraboldobl-webfont.svg#futura_stdXBdCnOb") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdextra_bold";
    src: url("./assets/fonts/futurastd-extrabold-webfont.eot");
    src: url("./assets/fonts/futurastd-extrabold-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-extrabold-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-extrabold-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-extrabold-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-extrabold-webfont.svg#futura_stdextra_bold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdextra_bold_oblique";
    src: url("./assets/fonts/futurastd-extraboldoblique-webfont.eot");
    src: url("./assets/fonts/futurastd-extraboldoblique-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-extraboldoblique-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-extraboldoblique-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-extraboldoblique-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-extraboldoblique-webfont.svg#futura_stdextra_bold_oblique") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdheavy";
    src: url("./assets/fonts/futurastd-heavy-webfont.eot");
    src: url("./assets/fonts/futurastd-heavy-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-heavy-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-heavy-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-heavy-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-heavy-webfont.svg#futura_stdheavy") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdheavy_oblique";
    src: url("./assets/fonts/futurastd-heavyoblique-webfont.eot");
    src: url("./assets/fonts/futurastd-heavyoblique-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-heavyoblique-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-heavyoblique-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-heavyoblique-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-heavyoblique-webfont.svg#futura_stdheavy_oblique") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "futura_stdlight";
    src: url("./assets/fonts/futurastd-light-webfont.eot");
    src: url("./assets/fonts/futurastd-light-webfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/futurastd-light-webfont.woff2") format("woff2"),
        url("./assets/fonts/futurastd-light-webfont.woff") format("woff"),
        url("./assets/fonts/futurastd-light-webfont.ttf") format("truetype"),
        url("./assets/fonts/futurastd-light-webfont.svg#futura_stdlight") format("svg");
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: "futura_stdbook";
    // color: #1c1c1c;
    background-color: #1c1c1c !important;
    overflow: hidden;
}

.qr-code-scanner {
    max-width: 500px;
    width: calc(100% - 30px);
    margin: 25px auto 0 auto;
    background: #090909;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 60%);
    border-radius: 8px;

    .col-12 {
        padding: 0;

        #reader {
            border: none !important;
            width: 100%;

            video {
                width: 100% !important;
                margin: auto;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            #reader__scan_region {
                overflow: hidden;

                #qr-shaded-region {
                    border-width: 25px !important;
                }
            }

            #reader__dashboard_section {
                margin: 10px 0 5px 0;
                color: white;

                #reader__dashboard_section_csr {
                    display: flex !important;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    padding: 0 10px;

                    .html5-qrcode-element {
                        margin-bottom: 8px;
                    }

                    #html5-qrcode-select-camera {
                        color: #090909;
                        border-radius: 4px;
                    }

                    select {
                        &.html5-qrcode-element {
                            overflow: hidden;
                        }
                    }
                }

                button {
                    &.html5-qrcode-element {
                        background-color: #65b5b9;
                        padding: 5px 8px;
                        border-radius: 4px;
                        border-color: #65b5b9;
                        color: #090909;
                        font-size: $fs-sm-reg;
                        font-family: Futura-Medium;
                    }
                }
            }
        }
    }
}

.modal {
    &.fade {
        .modal-dialog {
            transition: transform 300ms ease-out;
        }
    }

    &.show {
        .modal-dialog {
            width: calc(100% - (var(--bs-modal-margin) * 2));
        }
    }
}

.qr-scanner-result {
    padding-top: 15px;
    font-size: $fs-sm-reg;

    label {
        font-family: Poppins-reg;
        font-size: 13px;
    }

    textarea {
        width: 100%;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 4px;
        border-width: 1px;
        font-size: 14px;

        &:read-only {
            background-color: $themeColor9;
            color: $themeColor8;
        }
    }

    input {
        max-width: 100%;
        margin-bottom: 10px;
        padding: 2px 2px 0 5px;
        border-radius: 4px;
        border-width: 1px;
        font-size: 14px;

        &:read-only {
            background-color: $themeColor9;
            color: $themeColor8;
        }
    }

    .col-12 {
        &.save-btn-container {
            text-align: center;
            margin-top: 10px;

            .save-btn {
                background-color: $themeColor5;
                padding: 5px 8px;
                border-radius: 4px;
                border-color: $themeColor5;
                color: #090909;
            }
        }
    }
}

#html5-qrcode-anchor-scan-type-change {
    display: none !important;
}