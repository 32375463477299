@import "./variables.scss", "reset";

.pin-logo-img {
  width: 32vw;
}

.pin-screen-1 {
  height: 70vh;
  display: flex;
}

.pin-place {
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  color: $themeColor3;
  padding: 10px 30px;
  border: 1px solid $themeColor3;
  width: fit-content;
  margin: auto;
  margin-top: 60px;
  border-radius: 4px;
}

.pin-screen-1-center {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.pin-box {
  border-bottom: 2px solid $themeColor5 !important;
  color: $themeColor10;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  height: 80px;
  // width: 80px;
  font-size: 70px;
  text-align: center;
  caret-color: transparent;
}

.pin-box:focus {
  border-bottom: 2px solid $themeColor5 !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  outline: none !important;
  caret-color: transparent;
}

.pin-box-red {
  background-color: $themeColor2;
  border-bottom: 2px solid $themeColor7 !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  height: 80px;
  // width: 80px;
  font-size: 70px;
  text-align: center;
  caret-color: transparent;
  animation-name: example;
  animation-duration: 0.4s;
  color: $themeColor7;
}

.pin-box-red:focus {
  background-color: $themeColor2;
  border-bottom: 2px solid $themeColor7 !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  outline: none !important;
  caret-color: transparent;
}

.pin-box-green {
  border-bottom: 2px solid green !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  height: 80px;
  // width: 80px;
  caret-color: transparent;
  font-size: 70px;
  text-align: center;
  background-color: $themeColor2;
  color: green;
}

.pin-box-green:focus {
  border-bottom: 2px solid green !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  outline: none !important;
  caret-color: transparent;
  background-color: $themeColor2;
  color: green;
}

.pin-box-text {
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 23px;
  text-align: center;
  color: $themeColor10;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
}

.num-btn {
  background: $themeColor4;
  border-radius: 50%;
  border: 1px solid transparent;
  font-size: 30px;
  cursor: pointer;
  background-color: white;
  color: $themeColor6;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 20px 35px;
}

.align-num-pad {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 110px !important;
  text-align: center !important;

  .disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.align-otp-text {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  input {
    background-color: transparent;
  }
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 300px);
}

.flex-container-1 {
  display: flex;
  justify-content: space-evenly;
  row-gap: 10px;
  width: 100%;
  padding: 0 10%;
}

.home-page {
  .keypad-blk {
    padding-bottom: 100px;
    max-width: 600px;
    margin: 0 auto;
  }

  .password-wrap {
    padding-bottom: 30px;
    overflow: hidden;

    input {
      pointer-events: none;
      letter-spacing: 20px;
      margin-right: -20px;
    }
  }

  .num-btn {
    width: 80px;
    height: 80px;
    padding: 0;
    text-align: center;
  }

  .pin-box {
    border-color: transparent !important;
  }

  .pin-box:focus {
    border-color: transparent !important;
  }

  .pin-box-green {
    border-color: transparent !important;
  }

  .pin-box-red {
    border-color: transparent !important;
  }
}

.curser-pointer {
  cursor: pointer;
}

.employee-blk {
  padding-bottom: 30px;

  .search-wrap {
    max-width: 600px;

    .os-host {
      margin-top: -3px;
    }

    .os-host .os-viewport {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .form-group {
      position: relative;
      z-index: 0;

      input {
        font: 18px/22px "Poppins-reg";
        color: #fff;
        background: #000;
        width: 100%;
        padding: 20px 20px 20px 50px;
        border-radius: 10px;
        border: 0;

        &::placeholder {
          font-family: "Poppins-reg";
          color: #fbfbfb;
        }
      }

      .search-icon {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .search-list-blk {
      position: relative;
      // margin-top: -4px;
      z-index: 1;
      // position: absolute;
      // left: 0;
      // width: 100%;
      // height: 250px;
      // overflow-y: auto;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      width: 100%;

      &.hg {
        height: 250px;
      }

      ul {
        li {
          p {
            font: 18px/22px "Poppins-bold";
            color: #fff;
            padding: 15px;
            background: #000;
            cursor: pointer;
            border-top: 1px solid #cccccc;

            span {
              display: inline-block;
              font: 18px/22px "Poppins-reg";
              font-style: italic;
              color: #a6a6a6;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

.location-dropdown {
  padding-bottom: 30px;
  margin: 40px 8px 5px 8px;

  .search-wrap {
    max-width: 600px;
    position: relative;
    margin-inline: auto;

    &.remove-radius .form-group input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 1px solid #65b5b9;
    }

    .form-group {
      position: relative;
      z-index: 0;

      input {
        font: 18px/18px "Poppins-semibld";
        color: #fff;
        background: #000;
        width: 100%;
        padding: 15px 20px 15px 50px;
        border-radius: 10px;
        border: 0;

        &::placeholder {
          font-family: "Poppins-reg";
          color: #fbfbfb;
        }
      }

      .search-icon,
      .close-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .search-icon {
        left: 15px;
      }

      .close-icon {
        right: 15px;
      }

      .input-val {
        font: 18px/22px "Poppins-reg";
        color: #fff;
        position: absolute;
        left: 50px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .search-list-blk {
      // position: relative;
      // margin-top: -4px;
      z-index: 1;
      position: absolute;
      left: 0;
      // width: 100%;
      // height: 250px;
      // overflow-y: auto;
      // height: 250px;
      overflow-y: auto;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      width: 100%;
      transform: scaleY(0);
      transition: all 0.3s;
      transform-origin: top left;

      &.hg {
        height: 250px;
        transform: scaleY(1);
      }

      ul {
        li {

          // &:first-child{border-top: 1px solid #65b5b9;}
          &:first-child p {
            border-top: 0;
          }

          p {
            font: 18px/22px "Poppins-bold";
            color: #fff;
            padding: 15px;
            background: #000;
            cursor: pointer;
            border-top: 1px solid #a6a6a6;

            span {
              display: inline-block;
              font: 18px/22px "Poppins-reg";
              font-style: italic;
              color: #a6a6a6;
              padding-left: 5px;
            }

            &:hover {
              background: #65b5b9;
              color: #000;

              span {
                color: #000;
              }
            }
          }

          &:last-child p {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }
}

.Toastify__toast-container--top-center {
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.pageloader {
  width: 100%;
  height: 100%;
  background: rgb(46 46 46 / 95%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transform: translateZ(1px);
  -moz-transform: translateZ(1px);
  -o-transform: translateZ(1px);
  transform: translateZ(1px);
}

.pageloader {
  .loaderClass {
    width: 100px;

    .loaderColor1 {
      stroke: $themeColor5;
      fill: $themeColor5;
      stroke-width: 3px;
    }

    .loaderColor2 {
      stroke: #090909; // $colorPrimary2 => #090909
      fill: #090909; // $colorPrimary2 => #090909
      stroke-width: 3px;
    }

    .loaderColor3 {
      stroke: #6b6b6b; // $colorSecondary2 => #6b6b6b
      fill: #6b6b6b; // $colorSecondary2 => #6b6b6b
      stroke-width: 3px;
    }
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .location-dropdown {
    margin-top: 70px;
  }
}

@media (max-width: 767px) {
  .home-page {
    .logo-img-1 {
      left: 50%;
      transform: translateX(-50%);
      right: auto;
    }

    .password-wrap {
      input {
        font-size: 50px;
        letter-spacing: 15px;
        margin-right: -15px;
      }
    }

    .num-btn {
      width: 60px;
      height: 60px;
    }
  }
}

@media (max-width: 479px) {
  .home-page .password-wrap {
    padding-bottom: 15px;

    input {
      font-size: 35px;
      letter-spacing: 12px;
      padding-left: 12px;
      margin-right: 0;
    }
  }

  .home-page .keypad-blk {
    padding-bottom: 50px;
    max-width: 100%;
  }

  .home-page .pin-box,
  .home-page .pin-box-red,
  .home-page .pin-box-green {
    height: 40px;
    font-size: 40px;
  }

  .align-num-pad {
    height: 80px !important;
    width: 60px;

    .num-btn {
      font-size: 25px;
      padding-top: 5px;
    }
  }
}

.pageHeader {
  padding: 10px;
  display: flex;
  justify-content: center;
}